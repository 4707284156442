import React, { Suspense } from 'react';
import { SocketContext, socket } from './shared/context/socket';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronUp,
  faChevronDown,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faKey,
  faCheck,
  faSignInAlt,
  faSignOutAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import Auth from './auth/pages/Auth';
import Forgot from './auth/pages/ForgotPassword';
import Reset from './auth/pages/ResetPassword';
import Questions from './questions/pages/Questions';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';

library.add(
  faChevronUp,
  faChevronDown,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faKey,
  faCheck,
  faSignInAlt,
  faSignOutAlt,
  faExclamationTriangle
);

function App() {

  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  let routes;

  if (!token) {
    routes = (
      <Switch>
        <Route path="/auth" exact>
          <Auth />
        </Route>
        <Route path="/forgot" exact>
          <Forgot />
        </Route>
        <Route path="/reset/:token">
          <Reset />
        </Route>
        <Route path="*">
          <Auth />
        </Route>
      </Switch>
    );
  } else {
    routes = (
      <SocketContext.Provider value={socket}>
        <Switch>
          <Route path="/" exact>
            <Questions />
          </Route>
          <Route path="*">
            <Questions />
          </Route>
        </Switch>
      </SocketContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl: userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <main>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
