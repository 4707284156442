import React from 'react';


import ContentContainer from '../../shared/components/UIElements/ContentContainer';

import styles from './QASessions.module.css';

const QASessions = ({sessions, selected}) => {

    const selectionHandler = session => {
        selected(session);
    }

    return (
        <div className={styles.sessionsWrapper}>

        <ContentContainer title='Q&A Session List' classNameCC={styles.contentContainer}>
        <div className={styles.sessionListContainer}>
            {sessions && sessions.map(session => (
                <div key={session.id} className={`${styles.sessionItemWrapper}`} onClick={()=>selectionHandler(session)}>
                    <div className={styles.itemName}>{session.name}</div>
                    <div className={styles.itemQuestionCount}><span className={styles.questionCount}>Status: </span>{session.live ? "Open" : "Closed"}</div>
                </div>
            ))}
        </div>
        </ContentContainer>

        </div>
    )
}

export default QASessions;