import React, {useState, useEffect, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Actions.module.css';
import Button from '../../shared/components/UIElements/Buttons/Button';

const Action = ({ question, selectedQuestion}) => {

    const [waiting, setWaiting] = useState(true);
    const [selected, setSelected] = useState(false);
    const [answered, setAnswered ]= useState(false);

    useEffect(() => {
       if (question.answered) {
        setWaiting(false);
        setSelected(false);
        setAnswered(true);
       } else if (question.selected) {
        setWaiting(false);
        setSelected(true);
        setAnswered(false);
       } else {
        setWaiting(true);
        setSelected(false);
        setAnswered(false);
       }
    }, [question])

    return (
        <Fragment>
            {waiting && <Button title='select' onClick={()=>selectedQuestion(question)}/>}
            {answered && <div className={styles.questionAnsweredBadge}><FontAwesomeIcon icon="check-circle" size="lg" /><h3> &nbsp;Answered</h3></div>}
            {selected && <div className={styles.questionSelectedBadge}><FontAwesomeIcon icon="check-circle" size="lg" /><h3> &nbsp;Current</h3></div>}
        </Fragment>
    )
}

export default Action;