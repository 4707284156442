import React, { useContext }  from 'react';
import { useHistory } from "react-router-dom";


import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './Auth.css';
import Logo from '../../shared/components/UIElements/logo';



const Auth = props => {

  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

   

  const submitHandler = async event => {
    event.preventDefault();

   

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/auth/control-panel`,
        'POST',
        JSON.stringify({
          email: event.target.email.value,
          password: event.target.password.value,
        }),
        {
          'Content-Type': 'application/json'
        }
        );
        auth.login(response.userId, response.email, response.token);
        history.push("/");
    }
    catch (err) {
      console.log(err);
    }
  }

    return (
        <React.Fragment>
            <ErrorModal
                error={error}
                className={'fm-modal-sm'}
                onClear={clearError}
            />
           
            <div className="wrapper center col"> 
            <div className="auth-wrapper">
            
            {isLoading && <div className='spinner-center auth-spinner'><LoadingSpinner  /></div>}
            {!isLoading && <Card className="login-card" style={{width:'100%'}}>
           
           
            <Logo className='auth-logo' />
            <h1>Webinar Demo</h1>
            <p>Podium Control Panel</p>
           
            <form onSubmit={submitHandler}>
                <Input id="email" name="email" type="email" placeholder="email" inputClassName="no-group form-padding" />
                <Input id="password" name="password" type="password" placeholder="Password" inputClassName="no-group form-padding"/>
                <Button  type="submit" className="button-primary login-btn"  title="Login"/>
            </form>
            </Card>}
            </div>
            </div>
        </React.Fragment>
        );
};

export default Auth;