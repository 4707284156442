import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Card from '../../shared/components/UIElements/Card';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';

import './Auth.css';

const ResetPassword = props => {
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [message, setMessage] = useState();
  const token = useParams().token;
  const { register, watch, handleSubmit, errors } = useForm();

  const submitHandler = async data => {
    try {
      const response = await sendRequest(
        `https://api.docked.co/api/admin/update-password`,
        'PATCH',
        JSON.stringify({
          token: token,
          password: data.password,
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      if (response) {
        history.push('/auth');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const res = await sendRequest(
          `https://api.docked.co/api/auth/validate-token`,
          'POST',
          JSON.stringify({
            token: token,
          }),
          {
            'Content-Type': 'application/json',
          }
        );

        if (res) {
          console.log(res);
          setMessage(res.message);
        }
        setTokenIsValid(res.isValid);
      } catch (err) {
        console.log(err);
        setTokenIsValid(false);
        setMessage(err.message);
      }
    };
    checkToken();
  }, []);

  return (
    <React.Fragment>
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="wrapper center col">
        <div className="auth-wrapper">
          {!tokenIsValid ? (
            <Card className="forgot-card">
              <h1>Reset Password</h1>
              <p className="reset-sent-notice">{message}.</p>
              <Link className="forgot-link" to="/forgot">
                Forgot your password?
              </Link>
            </Card>
          ) : (
            <Card className="login-card" style={{ width: '100%' }}>
              <h1>Reset Password</h1>
              <form onSubmit={handleSubmit(submitHandler)}>
                <span className="inputError">
                  {errors.password &&
                    errors.password.type === 'required' &&
                    'required'}
                  {errors.password &&
                    errors.password.type === 'minLength' &&
                    'At least 8 characters are required!'}
                </span>
                <Input
                  id="password"
                  refs={register({ required: true, minLength: 8 })}
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="no-group form-padding"
                />

                <Input
                  id="passwordConfirm"
                  refs={register({
  validate: (value) => value === watch('password')
})}
                  name="passwordConfirm"
                  type="password"
                  placeholder="Confirm Password"
                  className="no-group form-padding"
                />
                <span>Password must be at least 8 characters</span>
                <Button
                  type="submit"
                  className="button-primary login-btn"
                  title="Reset Password"
                />
              </form>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
