import React from 'react';
import WaitingSpinner from '../../shared/components/UIElements/WaitingSpinner';


import styles from './Notice.module.css'


const Notice = ({ isLive }) => {

    return (
        <div className={styles.noticeWrapper}> 
            <div className={styles.noticeContainer}>
               {isLive ? <h1>Session is open and waiting for questions.</h1> : <h1>Session is closed.</h1>}
               {isLive && <h2>You do not need to refresh your browser</h2>}
               {isLive && <WaitingSpinner />}
            </div> 
        </div>

    )
}

export default Notice