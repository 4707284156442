import React from 'react'


import './logo.css'

 const Logo = ({imgClassName}) => {
    return (
        <div className="logo__wrapper">
             <img className={`logo-img ${imgClassName} `} src='/img/logo.svg' alt="logo" />
        </div>
    )
}


export default Logo;