import React from 'react';
import moment from 'moment';
import {motion} from 'framer-motion';

import styles from './QuestionItem.module.css';

import Action from './Actions';

const QuestionItem = ({question, selectedQuestion}) => {
    return (
        <motion.div
             className={styles.itemWrapper}
             initial={{ x:  -100, opacity: 0 }} 
                animate={{ x:  0, opacity: 1 }} 
                exit={{ x:  500, opacity: 0 }} 
                transition={{ duration: .5 }} 
            >
            <div className={styles.questionContent}>
                <p className={styles.question}>{question.question}</p>
                <p className={styles.info}>Asked by: {question.user.name} @ {moment(question.createdAt).format('LT')}</p>
            </div>
            <div className={styles.actions}>
                   <Action question={question} selectedQuestion={selectedQuestion} />
            </div>
        </motion.div>
    )
}

export default QuestionItem;