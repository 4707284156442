import React, {useState, useEffect, useContext} from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import Notice from './Notice'
import QuestionItem from './QuestionItem';

import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import styles from './QASession.module.css';


const QASession = ({questions, isLive}) => {

  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
    const [sortedQuestions, setSortedQuestions] = useState([]);
    const [currentQuestionId, setCurrentQuestionId] = useState();

    useEffect(() => {
        if (questions) {
            setSortedQuestions(questions.sort(compare))
        }
    }, [questions])

    const compare = (a, b) => {
        const ADate = a.createdAt;
        const BDate = b.createdAt;
    
        let comparison = 0;
        if (ADate < BDate) {
          comparison = 1;
        } else if (ADate > BDate) {
          comparison = -1;
        }
        return comparison;
      }

   const selectedQuestion = async question => {
    console.log(currentQuestionId);
    let currentQuestion = {}
    let cqid = null
    if (!currentQuestionId) {
        currentQuestion = questions.find(q => q.selected === true && q.answered === false);
        console.log(currentQuestion);
        if (currentQuestion) {
            cqid = currentQuestion.id
        }
    }
       console.log(question);
       setCurrentQuestionId(question.id)
       try {
        const response = await sendRequest(
            `${REACT_APP_API_URL}/api/qa/selected/${question.id}`,
            'PATCH',
            JSON.stringify({
                selected: true,
                previousQid: currentQuestionId || cqid
            }),
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
           console.log(response);
        }
        catch (err) {
          console.log(err);
        }
   }

    return (
        <div className='page-wrapper'>
            <div className={styles.sessionsWrapperBody}>
                    <AnimatePresence>
                        {isLive && sortedQuestions &&
                            sortedQuestions.map(q => (
                            q.approved && !q.answered && <QuestionItem key={q.id} question={q} selectedQuestion={selectedQuestion}/>
                            ))}
                        {sortedQuestions.length === 0 && <Notice isLive={isLive} />}
                        {sortedQuestions.length > 0 && !isLive && <Notice isLive={isLive} />}
                    </AnimatePresence>
                </div>
                <ErrorModal
                error={error}
                onClear={clearError}
            />
        </div>
    )
}

export default QASession;