import React from 'react';
import styles from './WaitingSpinner.module.css';

const WaitingSpinner = () => {
    return (
        <div style={{position: 'relative', width: '20rem', height:'20rem'}}>
            <div className={styles.waitingSpinner}></div>
        </div>
    )
}

export default WaitingSpinner;