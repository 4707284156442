import React, { useEffect, useState, useContext } from 'react';
import { SocketContext } from '../../shared/context/socket';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Header from '../../shared/components/header/Header';
import QASession from '../components/QASession';
import QASessions from '../components/QASessions';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import styles from './Question.module.css';

const Questions = () => {
  const { REACT_APP_API_URL } = process.env;
  const socket = useContext(SocketContext);
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const [sessions, setSessions] = useState();
  const [title, setTitle] = useState('Please Choose a Session');
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState();
  const [sessionState, setSessionState] = useState(false);
  const [questionStatus, setQuestionStatus] = useState();

  const [toggleData, setToggleData] = useState();

  const [showSession, setShowSession] = useState(false);
  const [showSessionSelector, setShowSessionSelector] = useState(false);

  useEffect(() => {

    console.log('socket');
    socket.on('Q&A', dataFromServer => {
      socketDataHandler(dataFromServer);
    });
    return () => {
      socket.off('Q&A');
    }
  }, [socket]);

  useEffect(() => {
    toggleSession(toggleData);
  }, [toggleData]);

  useEffect(() => {
    newQuestion && addQuestion(newQuestion);
  }, [newQuestion]);

  useEffect(() => {
   questionStatus && updateStatus(questionStatus);
  }, [questionStatus]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/qa/qa-sessions`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        if (responseData.sessions) {
          console.log(responseData.sessions);
          setSessions(responseData.sessions);
          setShowSessionSelector(true);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchSessions();
  }, []);

  const selectedSessionHandler = async session => {

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/qa/qa-session/${session.id}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      if (responseData.session) {
        localStorage.setItem(
          'sessionData',
          JSON.stringify({ sid: session.id })
        );
    
        
        if (session.qaQuestions) {
          setQuestions(() => [...responseData.session.qaQuestions]);
          setTitle(responseData.session.name);
          setSessionState(responseData.session.live);
          setShowSession(true);
          setShowSessionSelector(false);
        }
        
      }
    } catch (err) {
      console.log(err);
    }
  };

  const socketDataHandler = data => {
    console.log(data);
    if (data) {
      switch (data.action) {

        case 'approved':
          setNewQuestion(data.question);
          break;

        case 'selected':
          setQuestionStatus(data);
          break;

        case 'session toggle':
          setToggleData(data);
          break;

        default:
          break;
      }
    }
  };

  const updateStatus = data => {
    console.log('UPDTATE STATUS');
    console.log(data);
    if (data) {
      const updateQuestions = questions;
      if (updateQuestions.length > 0) {
        const updatedQuestions = updateQuestions.map(q => {
          q.selected = false;
          if (q.id === data.question.id) {
            q = data.question;
          }
          if (data.previousQuestion && q.id === data.previousQuestion.id) {
            q = data.previousQuestion;
          }
          return q;
        });
        console.log(updatedQuestions);
        setQuestions(()=>[...updatedQuestions])
      }
    }
  };

  const toggleSession = data => {
    if (sessions) {
      const updatedSessions = sessions;
      const sessionToggle = updatedSessions.find(sess => {
        return sess.id === data.sessionId;
      });

      sessionToggle.live = data.isLive;

      console.log(updatedSessions);
      setSessions(() => [...updatedSessions]);

      const sid = getCurentSessionId()

      if (sid === data.sessionId) {
        setSessionState(data.isLive);
      }
    }
  };

  const addQuestion = question => {
   
   const sid = getCurentSessionId();
   const duplicate = questions.find(q => q.id === question.id)

   if (sid === question.sessionId) {
     if (!duplicate) {
      setQuestions(current => [question, ...current]);
     }
   }
  };


  const resetView = () => {
    setQuestions(null);
    setTitle('Please Choose a Session');
    setShowSession(false);
    setShowSessionSelector(true);
  };

  const getCurentSessionId = () => {
    const data = localStorage.getItem('sessionData');

    if (data) {
      return JSON.parse(data).sid;
    }
  };

  return (
    <div id={styles.questions}>
     <ErrorModal error={error} onClear={clearError} />
    
      <div className={styles.contentWrapper}>
        <Header title={title} exitSession={resetView} />
        <div className={styles.contentBody}>
        {isLoading && <LoadingSpinner />}
          {!isLoading && showSession && (
            <QASession questions={questions} isLive={sessionState} />
          )}
          {!isLoading && showSessionSelector && (
            <QASessions sessions={sessions} selected={selectedSessionHandler} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Questions;
